import Vue from 'vue';
import Vuex from 'vuex';

import entry from './modules/entry';
import EntryProxy from '@/app/proxies/EntryProxy';

Vue.use( Vuex );
require( 'es6-promise' ).polyfill();

export default new Vuex.Store({
    strict: true,

    modules: {
        entry
    },

    state: {
        cookies: null,
        highscores: []
    },

    getters: {
        cookiesAllowed: state => state.cookies,
        getHighscores: state => state.highscores
    },

    mutations: {
        setCookies: ( state, payload ) =>
        {
            state.cookies = payload;
        },

        setHighscores: ( state, payload ) =>
        {
            state.highscores = payload;
        }
    },

    actions: {
        setCookies: ( context, payload ) =>
        {
            context.commit( 'setCookies', payload );
        },

        getHighscores: context =>
        {
            return new EntryProxy()
                .all()
                .then( response =>
                {
                    context.commit( 'setHighscores', response );
                    return response;
                })
                .catch( error =>
                {
                    return Promise.reject( error );
                });
        }
    }
});
