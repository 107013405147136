<template>
    <div id="app" :class="{ 'isOldIos': browserCheck.iOS }">
        <ynk-layout>
            <transition mode="out-in"
                        @enter="getAnimateIn"
                        @leave="getAnimateOut"
                        :css="false"
                        appear>
                <router-view :key="$route.path" ref="currentView"></router-view>
            </transition>
        </ynk-layout>
        <div class="cookie-overlay" v-if="!cookiesAllowed">
            <div class="cookie-panel">
                <p>Histor maakt gebruik van cookies voor metingsdoeleinden. Geef je voorkeur aan.</p>
                <div class="button-wrapper">
                    <p class="text-button" @click="cookiesAkkoord( 'false' )">Liever niet</p>
                    <button @click="cookiesAkkoord( 'true' )">Accepteer</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import './static/scss/app.scss';

    .cookie-overlay{
        position:fixed;
        width:100%;
        height:100%;
        top:0;
        left:0;
        display:flex;
        background-color:rgba( 0,0,0,0.2 );
        z-index:30;

        .cookie-panel{
            background-color:whitesmoke;
            height:auto;
            width:auto;
            margin:auto;
            margin-bottom:0;

            color:black;
            max-width:500px;
            padding:0.5rem;
            border-radius:0.5rem;

            p{
                font-size:0.8rem;
                margin-top:0.5rem;
            }

            .button-wrapper{
                display:flex;

                &>*{
                    display:inline-block;
                    flex-basis:50%;
                }

                button{
                    font-size:0.8rem;
                    min-width:1px;
                    box-shadow:none;
                }

                .text-button{
                    cursor:pointer;
                    display:inline-block;
                    text-decoration: underline;
                }
            }
        }
    }
</style>

<script>
    import { TimelineMax } from '@/vendor';
    import Main from '@/app/layouts/Main';
    import { browserCheck } from '@/app/mixins';

    export default {
        name: 'App',

        components: {
            'ynk-layout': Main
        },

        mixins: [ browserCheck ],

        computed: {
            cookiesAllowed()
            {
                return this.$store.getters.cookiesAllowed;
            }
        },

        watch: {
            'cookiesAllowed': function( pVal )
            {
                if( pVal )
                {
                    this.setCookie( 'histor-cookie-consent', pVal.toString(), 7 );
                }

                if( pVal === 'true' )
                {
                    this.cookieConsent = pVal.toString();
                    this.trackPageview();
                }
            }
        },

        mounted()
        {
            this.cookieConsent = this.getCookie( 'histor-cookie-consent' );

            if( this.cookieConsent !== null )
            {
                this.cookiesAkkoord( this.cookieConsent );

                if( this.cookieConsent === 'true' )
                {
                    this.trackPageview();
                }
            }

            window.addEventListener( 'resize', this.onResize );
            this.onResize();
        },

        methods: {
            trackPageview()
            {
                // cookies accepted
                console.log( 'here', this.cookieConsent );
                if( this.cookieConsent === 'true' )
                {
                    console.log( 'here', typeof window._rootData.fbq );
                    if( typeof window._rootData.fbq === 'function' )
                    {
                        window._rootData.fbq( 'init', '566693106867705' );
                        window._rootData.fbq( 'track', 'PageView' );
                    }
                }
            },

            onResize()
            {
                document.documentElement.style.setProperty( '--vh100', window.innerHeight + 'px' ); // fix for 100vh on iphones
            },

            cookiesAkkoord( pVal )
            {
                this.$store.dispatch( 'setCookies', pVal );
            },

            /* eslint-disable */
            setCookie( name, value, days )
            {
                var expires = "";
                if (days) {
                    var date = new Date();
                    date.setTime(date.getTime() + (days*24*60*60*1000));
                    expires = "; expires=" + date.toUTCString();
                }
                document.cookie = name + "=" + (value || "")  + expires + "; path=/";
            },

            getCookie( name )
            {
                var nameEQ = name + "=";
                var ca = document.cookie.split(';');
                for(var i=0;i < ca.length;i++) {
                    var c = ca[i];
                    while (c.charAt(0)==' ') c = c.substring(1,c.length);
                    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
                }
                return null;
            },

            getAnimateIn( el, done )
            {
                let tl = new TimelineMax({ onComplete: done });
                if( typeof this.$refs.currentView.getAnimateIn === 'function' )
                {
                    tl.add( this.$refs.currentView.getAnimateIn(), 0 );
                }
            },

            getAnimateOut( el, done )
            {
                let tl = new TimelineMax({ onComplete: done });
                if( typeof this.$refs.currentView.getAnimateOut === 'function' )
                {
                    tl.add( this.$refs.currentView.getAnimateOut(), 0 );
                }
            }
        }
    };
</script>
