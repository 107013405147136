import * as yup from 'yup';

export default {
    beforeCreate()
    {
        yup.setLocale({
            mixed: {
                required: obj => `${obj.label ? obj.label : obj.path} is verplicht` // `${obj.path} is default`
            },
            string: {
                min: obj => `${obj.path} moet minstens ${obj.min} karakters lang zijn`,
                matches: obj => `Dit is geen geldig ${obj.label}.`
            }
        });
    },

    data()
    {
        return {
            formConfig: {
                fullname: {
                    // name: 'firstname',
                    name: 'Naam',
                    placeholder: 'Naam',
                    autocomplete: 'given-name',
                    tabindex: 1
                },

                emailaddress: {
                    name: 'emailaddress',
                    placeholder: 'Email',
                    type: 'text',
                    tabindex: 2
                },

                kit: {
                    type: 'checkbox',
                    name: 'kit',
                    id: 'kit-checkbox',
                    tabindex: 3
                }
            },

            validationSchema: yup.object().shape({
                fullname: yup.string()
                    .label( 'Naam' )
                    .required()
                    /* eslint-disable-next-line */
                    .matches( /^([A-z\- ])+$/ )
                    .min( 2 ),
                emailaddress: yup.string()
                    .label( 'e-mail' )
                    .required()
                    .email( 'Dit is geen geldig mailadres' )
            })
        };
    }
};
